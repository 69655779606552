import * as Yup from 'yup';
import { find, isUndefined, map, omitBy, reduce } from 'lodash';
import { isNumberable } from './math.helper';
import { LangModel } from '../models/languages/language.model';

interface Data {
  [key: string]: any;
}

export function convertToFormData(
  formData: FormData,
  data: Data,
  parentKey: any = undefined,
) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      const dataKey = !isNumberable(key as any)
        ? `${parentKey}.${key}`
        : `${parentKey}[${key}]`;
      // if (data[key] instanceof File) {
      //   dataKey = parentKey;
      // }
      convertToFormData(formData, data[key], parentKey ? dataKey : key);
    });
  } else {
    const value: any = data ?? '';

    formData.append(parentKey, value);
  }
}

export function getFormData(data: Data) {
  const formData = new FormData();
  convertToFormData(formData, data);

  return formData;
}

export const createDynamicFields = (
  languages: Array<{ code: string; name: string }>,
  translate: (key: string, options?: Record<string, any>) => string,
  requiredLangCode: string = 'vi',
): { [key: string]: Yup.StringSchema } =>
  reduce(
    languages,
    (acc: { [key: string]: Yup.StringSchema }, lang) => {
      acc[lang.code] = Yup.string()
        .trim()
        .required(
          lang.code === requiredLangCode
            ? translate(`error:nameIsRequired`, { language: lang.name })
            : undefined,
        );
      return acc;
    },
    {},
  );

export const createDynamicValues = (
  languages: Array<{ id: number; code: string }>,
  localizations: Array<{ languageId: number; name: string }>,
): { [key: string]: string } =>
  reduce(
    languages,
    (acc, lang) => {
      if (lang.code) {
        const localization = find(localizations, { languageId: lang.id });
        acc[lang.code] = localization?.name || '';
      }
      return acc;
    },
    {} as { [key: string]: string },
  );

export const createLocalizations = (
  languages: Array<LangModel>,
  submitData: {
    [key: string]: any;
    localizations?: { languageId: number; id?: number }[];
  },
  typeId: number,
  dataId?: number,
) =>
  map(languages, (lang) => {
    const localization = {
      typeId,
      dataId: dataId || undefined,
      languageId: Number(lang.id),
      name: submitData[lang.code] || '',
      id: submitData.localizations?.find((item) => item.languageId === lang.id)
        ?.id,
    };

    // Loại bỏ các key có giá trị là undefined
    return omitBy(localization, isUndefined) as {
      id?: number;
      typeId: number;
      dataId?: number;
      languageId: number;
      name: string;
    };
  });
