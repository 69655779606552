export const ALL_ROUTE_CHAR = '*';
export const PAGE_INDEX_DEFAULT = 0;
export const PAGE_SIZE_DEFAULT = 10;

export const AppRouteConst = {
  HOME: '/',
  DASHBOARD: '/',
  TRANSACTION: '/trans',
  STORE: '/store',
  COMPANY: '/company',
  BOOTH: '/booth',

  THEME: {
    LIST: '/theme',
    CREATE: '/theme/c',
    EDIT: '/theme/:id',
  },
  THEME_CATEGORY: '/theme-category',
  THEME_TEMPLATE: {
    LIST: '/theme-template',
    CREATE: '/theme-template/c',
    EDIT: '/theme-template/:id',
  },
  LAYOUT_TEMPLATE: {
    LIST: '/layout-template',
    CREATE: '/layout-template/c',
    EDIT: '/layout-template/:id',
  },

  STICKER: {
    LIST: '/sticker',
    CREATE: '/sticker/c',
    EDIT: '/sticker/:id',
  },
  LANG: {
    LIST: '/lang',
    CREATE: '/lang/c',
    EDIT: '/lang/:id',
  },
  STICKER_CATEGORY: '/sticker-category',
  STICKER_TEMPLATE: {
    LIST: '/sticker-template',
    CREATE: '/sticker-template/c',
    EDIT: '/sticker-template/:id',
  },

  PROMOTION_CAMPAIGN: '/promotion-campaign',
  VOUCHER: {
    LIST: '/voucher',
    CREATE: '/voucher/c',
    EDIT: '/voucher/:id',
  },
  REPORT: {
    LIST: '/report',
    REPORT_DASHBOARD: '/report',
    REPORT_BY_SHOOTING_COUNT: '/report/shooting-count',
    REPORT_SHOOTING_DURATION: '/report/shooting-duration',
    REPORT_DEPOSIT_LOG: '/report/deposit-log',
    REPORT_ERROR_BOOTH: '/report/error-booth',
  },
  LOCATION: '/location',
  USER: '/user',
  PROFILE: {
    PREFIX: '/profile',
    MY_PROFILE: '/profile/my-profile',
    CHANGE_PASSWORD: '/profile/change-password',
    DETAIL: '/profile/d/:id',
  },
  SETTING: {
    LIST: '/setting',
    SETTING_CAPTURE_POSITION: {
      LIST: '/setting/capture-position',
      CREATE: '/setting/capture-position/c',
      EDIT: '/setting/capture-position/:id',
    },
    SETTING_CAPTURE_POSITION_TEMPLATE: {
      LIST: '/setting/capture-position-template',
      CREATE: '/setting/capture-position-template/c',
      EDIT: '/setting/capture-position-template/:id',
    },
    SETTING_UI_TEMPLATE_BOOTH: {
      LIST: '/setting/ui-template-booth',
      CREATE: '/setting/ui-template-booth/c',
      EDIT: '/setting/ui-template-booth/:id',
    },
    SETTING_BOOTH_BACKGROUND: {
      LIST: '/setting/booth-background',
      CREATE: '/setting/booth-background/c',
      EDIT: '/setting/booth-background/:id',
    },
    SETTING_BOOTH_VERSION: {
      LIST: '/setting/booth-version',
      CREATE: '/setting/booth-version/c',
      EDIT: '/setting/booth-version/:id',
    },
    SETTING_BOOTH_BACKGROUND_CATEGORY: '/setting/booth-background-category',
    SETTING_BOOTH_BACKGROUND_TEMPLATE: {
      LIST: '/setting/booth-background-template',
      CREATE: '/setting/booth-background-template/c',
      EDIT: '/setting/booth-background-template/:id',
    },
    SETTING_NEWS: {
      LIST: '/setting/news',
      CREATE: '/setting/news/c',
      EDIT: '/setting/news/:id',
    },
    SETTING_FAQ: {
      LIST: '/setting/faq',
      CREATE: '/setting/faq/c',
      EDIT: '/setting/faq/:id',
    },

    // setting photo filter
    SETTING_PHOTO_FILTER: {
      LIST: '/setting/photo-filter',
      CREATE: '/setting/photo-filter/c',
      EDIT: '/setting/photo-filter/:id',
    },
    SETTING_PHOTO_FILTER_TEMPLATE: {
      LIST: '/setting/photo-filter-template',
      CREATE: '/setting/photo-filter-template/c',
      EDIT: '/setting/photo-filter-template/:id',
    },
  },
  GUIDE: '/guide',
  PAGE_403: '/403',
};

export const AuthRouteConst = {
  AUTH: '/auth',
  LOGIN: '/auth/login',
};

export const QUERY_STRING = {
  SEARCH_TEXT: 's',
  ID: 'id',
  DATE: 'd',
  START_DATE: 'sd',
  END_DATE: 'ed',
  TRANSACTION: 't',
  PAGE: 'p',
  PAGE_SIZE: 'ps',
  ACTIVE: 'act',
  STATUS: 'st',
  PAYMENT_METHOD: 'pm',
  CAPTURE_MODE: 'cm',
  DEPOSIT_TYPE: 'dt',
  SELECT_SEARCH_ID: 'ssid',
  SELECT_SEARCH_NAME: 'ssn',
  LAYOUT_ID: 'lid',
  LAYOUT_NAME: 'ln',
  COMPANY_ID: 'comid',
  COMPANY_NAME: 'comn',
  STORE_ID: 'storeid',
  STORE_NAME: 'storen',
};

export const PAGINATION_CONST = {
  PAGE_DEFAULT: 0,
  PAGE_SIZE_DEFAULT: 20,
};

export const NoNeedPermissionRoute = [AppRouteConst.PAGE_403];
