import { PREFIX_API } from '../../constants/api.const';
import { preprocessGetQuery } from '../../helpers/api.helper';
import { getFormData } from '../../helpers/form-data.helper';
import {
  ActiveLangRequestModel,
  ActiveLangResponseModel,
  GetLanguageRequestModel,
  GetLanguageResponseModel,
  InactiveLangRequestModel,
  InactiveLangResponseModel,
  SaveLangRequestModel,
  SaveLanguageResponseModel,
} from '../../models/languages/language.model';
import { GetStickerRequestModel } from '../../models/sticker/sticker.model';
import authorizedRequest from '../request/authorizedRequest';

export function getLanguage(payload: GetLanguageRequestModel) {
  return authorizedRequest.get<
    GetLanguageResponseModel,
    GetLanguageResponseModel
  >(`${PREFIX_API.GENERAL}/languages?${preprocessGetQuery(payload)}`);
}

export function createLang(payload: SaveLangRequestModel) {
  const formDataPayload = getFormData(payload);
  return authorizedRequest.post<
    SaveLanguageResponseModel,
    SaveLanguageResponseModel
  >(`${PREFIX_API.GENERAL}/languages`, formDataPayload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function getLang(payload: GetStickerRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetLanguageResponseModel,
      GetLanguageResponseModel
    >(`${PREFIX_API.GENERAL}/languages/${payload?.id}`);
  }
}

export function updateLang(payload: SaveLangRequestModel) {
  const formDataPayload = getFormData(payload);

  if (payload?.id) {
    return authorizedRequest.put<
      SaveLanguageResponseModel,
      SaveLanguageResponseModel
    >(`${PREFIX_API.GENERAL}/languages/${payload?.id}`, formDataPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export function inactiveLang(payload: InactiveLangRequestModel) {
  return authorizedRequest.put<
    InactiveLangResponseModel,
    InactiveLangResponseModel
  >(`${PREFIX_API.GENERAL}/languages/${payload?.id}/inactive`);
}

export function activeLang(payload: ActiveLangRequestModel) {
  return authorizedRequest.put<
    ActiveLangResponseModel,
    ActiveLangResponseModel
  >(`${PREFIX_API.GENERAL}/languages/${payload?.id}/active`);
}
