import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  createLangAction,
  getLangAction,
  getLangDetailAction,
  updateLanguageAction,
} from './language.action';
import { LangStateModel } from '../../../models/languages/language.model';

const initialState: LangStateModel = {
  listLanguage: null,
  loadingList: false,
  LanguageDetail: null,
  loadingDetail: false,

  triggerSaveLang: null,
};

export const langSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLangStateAction: (
      state,
      action: PayloadAction<Partial<LangStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as LangStateModel),
    resetLangStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLangAction.pending, (state) => ({
        ...state,
        listLanguage: null,
        loadingList: true,
      }))
      .addCase(getLangAction.fulfilled, (state, { payload }) => ({
        ...state,
        listLanguage: payload?.data || null,
        loadingList: false,
      }))
      .addCase(getLangAction.rejected, (state, action) => {
        // Handle rejected state
      })
      .addCase(getLangDetailAction.pending, (state) => ({
        ...state,
        LanguageDetail: null,
        loadingDetail: true,
      }))
      .addCase(getLangDetailAction.fulfilled, (state, { payload }) => ({
        ...state,
        LanguageDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getLangDetailAction.rejected, (state) => ({
        ...state,
        LanguageDetail: null,
        loadingDetail: false,
      }))
      .addCase(updateLanguageAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLang: { id: uuidv4() },
      }))

      .addCase(createLangAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLang: { id: uuidv4() },
      }));
  },
});

export const { setLangStateAction, resetLangStateAction } = langSlice.actions;

export default langSlice.reducer;
