import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ActiveLangRequestModel,
  GetLanguageRequestModel,
  InactiveLangRequestModel,
  SaveLangRequestModel,
} from '../../../models/languages/language.model';
import {
  activeLang,
  createLang,
  getLang,
  getLanguage,
  inactiveLang,
  updateLang,
} from '../../../api/language/lang.api';
import { GetStickerRequestModel } from '../../../models/sticker/sticker.model';

export const getLangAction = createAsyncThunk(
  'GET_LANGUAGES_REQUEST',
  async (payload: GetLanguageRequestModel, { rejectWithValue }) => {
    try {
      return await getLanguage(payload);
    } catch (err: any) {
      // TODO: refactor types
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const createLangAction = createAsyncThunk(
  'CREATE_LANGUAGE_REQUEST',
  async (payload: SaveLangRequestModel, { rejectWithValue }) => {
    try {
      return await createLang(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const getLangDetailAction = createAsyncThunk(
  'GET_LANG_REQUEST',
  async (payload: GetStickerRequestModel) => getLang(payload),
);

export const updateLanguageAction = createAsyncThunk(
  'UPDATE_LANG_REQUEST',
  async (payload: SaveLangRequestModel, { rejectWithValue }) => {
    try {
      return await updateLang(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveLangAction = createAsyncThunk(
  'INACTIVE_LANG_REQUEST',
  async (payload: InactiveLangRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveLang(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeLangAction = createAsyncThunk(
  'ACTIVE_LANG_REQUEST',
  async (payload: ActiveLangRequestModel, { rejectWithValue }) => {
    try {
      return await activeLang(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
