import { createAsyncThunk } from '@reduxjs/toolkit';
import { listBoothBackgroundCategory } from 'api/booth-background/booth-background-category.api';
import { listBoothBackgroundTemplate } from 'api/booth-background/booth-background-template.api';
import { listBoothBackground } from 'api/booth-background/booth-background.api';
import { listBooth } from 'api/booth/booth.api';
import { listCompany } from 'api/company/company.api';
import { listLayoutTemplate } from 'api/layout/layout-template.api';
import { listLayout } from 'api/layout/layout.api';
import { listLocation } from 'api/location/location.api';
import { listPromotionCampaign } from 'api/promotion/promotion-campaign.api';
import { listCapturePositionTemplate } from 'api/setting/capture-position/capture-position-template.api';
import { listCapturePosition } from 'api/setting/capture-position/capture-position.api';
import { listPhotoFilter } from 'api/setting/photo-filter/photo-filter.api';
import { listStickerCategory } from 'api/sticker/sticker-category.api';
import { listStickerTemplate } from 'api/sticker/sticker-template.api';
import { listSticker } from 'api/sticker/sticker.api';
import { listStore } from 'api/store/store.api';
import { listThemeCategory } from 'api/theme/theme-category.api';
import { listThemeTemplate } from 'api/theme/theme-template.api';
import { listTheme } from 'api/theme/theme.api';
import { listUserRoles } from 'api/user/user.api';
import { ListBoothBackgroundCategoryRequestModel } from 'models/booth-background/booth-background-category.model';
import { ListBoothBackgroundTemplateRequestModel } from 'models/booth-background/booth-background-template.model';
import { ListBoothBackgroundRequestModel } from 'models/booth-background/booth-background.model';
import { ListBoothRequestModel } from 'models/booth/booth.model';
import { ListCompanyRequestModel } from 'models/company/company.model';
import { ListLayoutTemplateRequestModel } from 'models/layout/layout-template.model';
import { ListLayoutRequestModel } from 'models/layout/layout.model';
import {
  ListLocationRequestModel,
  LocationType,
} from 'models/location/location.model';
import { ListPromotionCampaignRequestModel } from 'models/promotion/promotion-campaign.model';
import { ListCapturePositionTemplateRequestModel } from 'models/setting/capture-position/capture-position-template.model';
import { ListCapturePositionRequestModel } from 'models/setting/capture-position/capture-position.model';
import { ListPhotoFilterRequestModel } from 'models/setting/photo-filter/photo-filter.model';
import { ListStickerCategoryRequestModel } from 'models/sticker/sticker-category.model';
import { ListStickerTemplateRequestModel } from 'models/sticker/sticker-template.model';
import { ListStickerRequestModel } from 'models/sticker/sticker.model';
import { ListStoreRequestModel } from 'models/store/store.model';
import { ListThemeCategoryRequestModel } from 'models/theme/theme-category.model';
import { ListThemeTemplateRequestModel } from 'models/theme/theme-template.model';
import { ListThemeRequestModel } from 'models/theme/theme.model';
import { ListUserRolesRequestModel } from 'models/user/user.model';

export const listLocationAction = createAsyncThunk(
  'LIST_PREFETCH_LOCATION_REQUEST',
  async (payload: ListLocationRequestModel) => listLocation(payload),
);

export const listProvinceAction = createAsyncThunk(
  'LIST_PREFETCH_PROVINCE_REQUEST',
  async (payload: ListLocationRequestModel) =>
    listLocation({ ...payload, typeId: LocationType.PROVINCE }),
);

export const listDistrictAction = createAsyncThunk(
  'LIST_PREFETCH_DISTRICT_REQUEST',
  async (payload: ListLocationRequestModel) =>
    listLocation({ ...payload, typeId: LocationType.DISTRICT }),
);

export const listWardAction = createAsyncThunk(
  'LIST_PREFETCH_WARD_REQUEST',
  async (payload: ListLocationRequestModel) =>
    listLocation({ ...payload, typeId: LocationType.WARD }),
);

export const listPrefetchCompanyAction = createAsyncThunk(
  'LIST_PREFETCH_COMPANY_REQUEST',
  async (payload: ListCompanyRequestModel) => listCompany(payload),
);

export const listPrefetchStoreAction = createAsyncThunk(
  'LIST_PREFETCH_STORE_REQUEST',
  async (payload: ListStoreRequestModel) => listStore(payload),
);

export const listPrefetchUserRolesAction = createAsyncThunk(
  'LIST_PREFETCH_USER_ROLES_REQUEST',
  async (payload: ListUserRolesRequestModel) => listUserRoles(payload),
);

export const listPrefetchBoothAction = createAsyncThunk(
  'LIST_PREFETCH_BOOTH_REQUEST',
  async (payload: ListBoothRequestModel) => listBooth(payload),
);

export const listPrefetchLayoutAction = createAsyncThunk(
  'LIST_PREFETCH_LAYOUT_REQUEST',
  async (payload: ListLayoutRequestModel) => listLayout(payload),
);

// Theme
export const listPrefetchThemeAction = createAsyncThunk(
  'LIST_PREFETCH_THEME_REQUEST',
  async (payload: ListThemeRequestModel) => listTheme(payload),
);
export const listPrefetchThemeTemplateAction = createAsyncThunk(
  'LIST_PREFETCH_THEME_TEMPLATE_REQUEST',
  async (payload: ListThemeTemplateRequestModel) => listThemeTemplate(payload),
);
export const listPrefetchThemeCategoryAction = createAsyncThunk(
  'LIST_PREFETCH_THEME_CATEGORY_REQUEST',
  async (payload: ListThemeCategoryRequestModel) => listThemeCategory(payload),
);

export const listPrefetchLayoutTemplateAction = createAsyncThunk(
  'LIST_PREFETCH_LAYOUT_TEMPLATE_REQUEST',
  async (payload: ListLayoutTemplateRequestModel) =>
    listLayoutTemplate(payload),
);

export const listPrefetchPromotionCampaignAction = createAsyncThunk(
  'LIST_PREFETCH_PROMOTION_CAMPAIGN_REQUEST',
  async (payload: ListPromotionCampaignRequestModel) =>
    listPromotionCampaign(payload),
);

// Sticker
export const listPrefetchStickerCategoryAction = createAsyncThunk(
  'LIST_PREFETCH_STICKER_CATEGORY_REQUEST',
  async (payload: ListStickerCategoryRequestModel) =>
    listStickerCategory(payload),
);
export const listPrefetchStickerAction = createAsyncThunk(
  'LIST_PREFETCH_STICKER_REQUEST',
  async (payload: ListStickerRequestModel) => listSticker(payload),
);
export const listPrefetchStickerTemplateAction = createAsyncThunk(
  'LIST_PREFETCH_STICKER_TEMPLATE_REQUEST',
  async (payload: ListStickerTemplateRequestModel) =>
    listStickerTemplate(payload),
);

// booth background
export const listPrefetchBoothBackgroundCategoryAction = createAsyncThunk(
  'LIST_PREFETCH_BOOTH_BACKGROUND_CATEGORY_REQUEST',
  async (payload: ListBoothBackgroundCategoryRequestModel) =>
    listBoothBackgroundCategory(payload),
);
export const listPrefetchBoothBackgroundAction = createAsyncThunk(
  'LIST_PREFETCH_BOOTH_BACKGROUND_REQUEST',
  async (payload: ListBoothBackgroundRequestModel) =>
    listBoothBackground(payload),
);
export const listPrefetchBoothBackgroundTemplateAction = createAsyncThunk(
  'LIST_PREFETCH_BOOTH_BACKGROUND_TEMPLATE_REQUEST',
  async (payload: ListBoothBackgroundTemplateRequestModel) =>
    listBoothBackgroundTemplate(payload),
);

// setting
export const listPrefetchCapturePositionAction = createAsyncThunk(
  'LIST_PREFETCH_CAPTURE_POSITION_REQUEST',
  async (payload: ListCapturePositionRequestModel) =>
    listCapturePosition(payload),
);

export const listPrefetchCapturePositionTemplateAction = createAsyncThunk(
  'LIST_PREFETCH_CAPTURE_POSITION_TEMPLATE_REQUEST',
  async (payload: ListCapturePositionTemplateRequestModel) =>
    listCapturePositionTemplate(payload),
);

export const listPrefetchPhotoFilterAction = createAsyncThunk(
  'LIST_PREFETCH_PHOTO_FILTER_REQUEST',
  async (payload: ListPhotoFilterRequestModel) => listPhotoFilter(payload),
);
